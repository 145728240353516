import './styles/confirm.css';
import { Checkbox } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { capitalizeFirstLetter } from '../../../constants/capitalizeFirstLetter';
import { setUserData, updateChatConnection } from 'store/actions/userActions';
import API from '../../../api';
import Button from '../../../components/UI/Button';
import Keys from '../../../constants/helper';
import TextInput from '../../../components/CustomComponents/TextInput';
import useNestedCheckError from '../../../constants/useNestedCheckError';
import { REGISTRATION_BORROWER_INVITE_CANCELED } from '../../../constants/routes';

const initState = {
  password: {
    value: '',
    error: null,
    required: true,
    errorMessage: 'Fill this label',
  },
  repeatPassword: {
    value: '',
    error: null,
    required: true,
    errorMessage: 'Fill this label',
  },
  accept: {
    value: false,
    error: null,
    required: true,
    errorMessage: 'You have to agree to TOS and PP',
  },
};

function AgreementsList({ role, docs }) {
  function Link({ entry }) {
    return (
      <a href={entry[1]} target="_blank" rel="noreferrer">
        {entry[0]}
      </a>
    );
  }

  if (role === 'broker') {
    const links = Object.entries(docs);

    if (links.length < 3) {
      return null;
    }

    return (
      <>
        I have read and agree to
        <Link entry={links[1]} /> and
        <Link entry={links[2]} />.
      </>
    );
  }
  const links = Object.entries(docs).filter(([label]) => label !== 'Broker Fee Agreement');

  if (links.length < 2) {
    return null;
  }

  return (
    <>
      I have read and agree to <Link entry={links[0]} /> and
      <Link entry={links[1]} />.
    </>
  );
}

export default function BorrowerRegistration() {
  const [field, setFields] = useState(initState);
  const { checkEmpty } = useNestedCheckError(field, setFields);
  const [responseError, setResponseError] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [agreements, setAgreements] = useState({});
  // const [completeByBDR, setCompleteByBDR] = useState(false)

  const token = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.get('token');

    // eslint-disable-next-line
  }, [location]);

  const role = useMemo(() => location.pathname?.split('/')[2], [location]);

  useEffect(() => {
    API(token)
      .get('/sign_in/borrower/register/from_invite')
      .then(({ data }) => {
        if (data.password_exist) {
          const userData = {
            avatar: data.profile_photo,
            role: data.role,
            ...data,
          };

          localStorage.setItem(Keys.JWT_TOKEN, data.access_token);
          dispatch(updateChatConnection());
          dispatch(setUserData({ ...userData, token: data.access_token }));

          setTimeout(() => history.push('/lar_app/create'), 1e3);
        }
      })
      .catch((error) => {
        if (error.response.data.error === 'invite canceled') {
          history.push(REGISTRATION_BORROWER_INVITE_CANCELED);
        }
      });

    // eslint-disable-next-line
  }, [token, role, history]);

  function onSubmit(event) {
    event.preventDefault();
    const { error, obj } = checkEmpty(field);
    setFields(obj);

    if (error) {
      return;
    }

    if (field.password.value.length <= 7) {
      setFields((prevState) => ({
        ...prevState,
        password: {
          ...prevState.password,
          error: true,
          errorMessage: 'Enter at least 8 characters',
        },
      }));

      return;
    }
    if (field.repeatPassword.value !== field.password.value) {
      setFields((prevState) => ({
        ...prevState,
        repeatPassword: {
          ...prevState.repeatPassword,
          error: true,
          errorMessage: 'Passwords do not match',
        },
      }));

      return;
    }

    API(token)
      .post('/sign_in/borrower/register/from_invite', { password: field.password.value })
      .then(({ data }) => {
        if (data.access_token) {
          localStorage.setItem(Keys.JWT_TOKEN, data.access_token);
        } else {
          localStorage.setItem(Keys.JWT_TOKEN, token);
        }

        dispatch(
          setUserData({
            role: data.role,
            token: data.access_token,
          }),
        );

        localStorage.setItem(Keys.USER_DATA, JSON.stringify(data));
        API()
          .get('/LAR/creation/data_for_form', {
            params: {
              borrower_id: data.id,
              creator: data.role,
              originator: data.role,
              // type,
            },
          })
          .then((res) => {
            console.log(res);
            history.push(`/lar_app/create${res.data.token}`);
          });
      })
      .catch(({ response }) => {
        if (response?.data?.error) {
          setResponseError(response.data.error);
        }
      });
  }

  const onChange = (event) => {
    const { value, name, type, checked } = event.target;

    setFields((prevState) => ({
      ...prevState,
      [name]: {
        error: null,
        value: type === 'checkbox' ? checked : value,
      },
    }));

    setResponseError(null);
  };

  useEffect(() => {
    API()
      .get('/common/iharmoni-terms')
      .then(({ data }) => data?.documents)
      .then((docs) =>
        Object.fromEntries(
          Object.entries(docs).filter(([name]) => ['Privacy Policy', 'Terms of Service'].includes(name)),
        ),
      )
      .then(setAgreements);
  }, [role]);

  return (
    <div className="wrapper_lender_registration">
      <div className="lender_registration_confirm main_wrapper_auth_body">
        <div className="wrapper_auth wrapper_verification">
          <h3 className="auth_head">
            We're glad you're here.
            <br /> Now, let's get started.
          </h3>
          <h3 className="auth_title">Select a Password</h3>
          <form className="wrapper_auth__inputs" onSubmit={onSubmit}>
            <TextInput
              autoComplete="on"
              error={field.password.error}
              errorMessage={field.password.errorMessage}
              htmlFor="password"
              onChange={onChange}
              showPassIcon
              title="New password"
              type="password"
              value={field.password.value}
            />
            <p style={{ margin: '-24px 0 0' }} className="auth_describe">
              Password must contain at least 8 characters.
            </p>
            <TextInput
              value={field.repeatPassword.value}
              autoComplete="on"
              error={field.repeatPassword.error}
              errorMessage={field.repeatPassword.errorMessage}
              htmlFor="repeatPassword"
              onChange={onChange}
              showPassIcon
              title="New password confirmation"
              type="password"
            />
            {Object.keys(agreements).length > 0 && (
              <Checkbox name="accept" checked={field.accept.value} onChange={onChange}>
                <AgreementsList role={role} docs={agreements} />
              </Checkbox>
            )}
            {responseError && (
              <div className="wrapper_form_error">
                <span>{capitalizeFirstLetter(responseError)}</span>
              </div>
            )}
            <Button
              className="btn_darkblue"
              disabled={
                !field.password.value.length ||
                !field.repeatPassword.value.length ||
                (!field.accept.value && Object.keys(agreements).length > 0)
              }
              text="Create account"
              type="submit"
            />
          </form>
        </div>
      </div>
    </div>
  );
}
